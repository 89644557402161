import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  realtimeExecutionLogs: [],
};
export const executionSlice = createSlice({
  name: "executionLogSlice",
  initialState,
  reducers: {
    setRealtimeExecutionLogs: (state, action) => {
      state.realtimeExecutionLogs = action.payload;
    },
  },
});

export const { setRealtimeExecutionLogs } = executionSlice.actions;

export const executionReducer = executionSlice.reducer;
