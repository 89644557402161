import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import { Grid, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import { callSnackbar } from "../atoms/snackbarProvider/callSnackbar";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { ApiService } from "../../services/api.services";
import { persistor } from "../../store/store";
import { tdm_version } from "../../config";

export default function LogoutDrawer() {
  const [state, setState] = React.useState({
    right: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ right: open });
  };

  const handleLogout = () => {
    React.startTransition(() => {
      Cookies.remove("tdmAccessToken");
      Cookies.remove("tdmRefreshToken");
      Cookies.remove("activeStep");
      persistor.purge();
      ApiService.removeInterceptors();
      navigate("/signin", { replace: true });
      callSnackbar(dispatch, "success", "Logged out successfully");
    });
  };
  const userData = useSelector((state) => state?.signInReducer?.userData);

  const nameParts = userData?.fullName?.split(" ");

  const firstLetter = nameParts && nameParts[0]?.charAt(0)?.toUpperCase(); // Get the first letter
  const lastLetter = nameParts && nameParts[1]?.charAt(0)?.toUpperCase();

  return (
    <>
      <Button
        onClick={toggleDrawer(true)}
        variant="contained"
        sx={{
          background: "#5E35B1",
          borderRadius: "50%",
          color: "white",
          minWidth: 0,
          fontSize: "18px",
          paddingX: "15px",
          paddingY: "10px",
          "&:hover": { backgroundColor: "#5E35B1" },
        }}
      >
        {firstLetter + lastLetter}
      </Button>
      <Drawer anchor="right" open={state.right} onClose={toggleDrawer(false)}>
        <Grid container sx={{ padding: "1rem" }}>
          <Grid
            item
            xs={12}
            textAlign={"right"}
            onClick={toggleDrawer(false)}
            sx={{ cursor: "pointer" }}
          >
            <CloseIcon />
          </Grid>
          <Grid item>
            <Grid
              role="presentation"
              onKeyDown={toggleDrawer(false)}
              container
              spacing={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "95vh",
                width: "550px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      borderRadius: "50%",
                      height: "10rem",
                      width: "10rem",
                      background: "#D4CCFD",
                      display: "flex",
                      color: "#150E44",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "60px",
                        fontWeight: "300",
                        fontFamily: "Noto Sans",
                      }}
                      variant="h2"
                    >
                      {firstLetter + lastLetter}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginTop: "1.5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        marginBottom: "0.4rem",
                        fontSize: "20px",
                        fontWeight: "500",
                        fontFamily: "Noto Sans",
                      }}
                    >
                      {userData?.fullName}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "Noto Sans",
                      }}
                    >
                      {userData?.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={handleLogout}>
                  Logout
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ position: "absolute", bottom: 0, mb: "0.8rem" }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  Powered by{" "}
                  <a
                    href="https://www.cabotsolutions.com/"
                    style={{
                      textDecoration: "none",
                      color: "#2f6c98",
                      fontWeight: "500",
                      fontSize: "14px",
                    }}
                  >
                    Cabot Technology Solutions
                  </a>{" "}
                  | {tdm_version}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}
