import { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import Cookies from "js-cookie";
import { BASE_URL } from "../../config";
import { setRealtimeExecutionLogs } from "./executionSlice";
import { useDispatch } from "react-redux";
import { setConfigurationIdWithStatusCompleted } from "../configurations/configurationSlice";

const SignalRConnection = () => {
  const [executionStatus, setExecutionStatus] = useState([]);
  const userToken = Cookies.get("tdmAccessToken");
  const hubUrl = `${BASE_URL}/tdm/executionStatusHub`;
  const dispatch = useDispatch();

  useEffect(() => {
    // Create the SignalR connection
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl, {
        accessTokenFactory: () => userToken,
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    // Function to start the connection
    const startConnection = async () => {
      try {
        await newConnection.start();

        newConnection.on("ReceiveExecutionStatus", (jsonString) => {
          const statusEvent = JSON.parse(jsonString);

          // Group by ExecutionId and update state
          if (statusEvent?.Message === "Data Populated Successfully.") {
            dispatch(setConfigurationIdWithStatusCompleted(executionStatus));
          }
          setExecutionStatus((prevStatus) => {
            const existingGroup = prevStatus.find(
              (group) => group.ExecutionId === statusEvent.ExecutionId
            );

            if (existingGroup) {
              return prevStatus.map((group) =>
                group.ExecutionId === statusEvent.ExecutionId
                  ? {
                      ...group,
                      statuses: [...group.statuses, statusEvent],
                    }
                  : group
              );
            } else {
              return [
                ...prevStatus,
                {
                  ExecutionId: statusEvent.ExecutionId,
                  statuses: [statusEvent],
                },
              ];
            }
          });
        });
      } catch (error) {
        // console.error("Connection error:", error);
        setTimeout(startConnection, 5000);
      }
    };

    // Start the connection
    if (newConnection.state === "Disconnected") {
      startConnection();
    }

    return () => {
      if (newConnection) {
        newConnection
          .stop()
          .then(() => console.log("Disconnected from the SignalR hub"));
      }
    };
  }, [hubUrl, userToken]);

  useEffect(() => {
    dispatch(setRealtimeExecutionLogs(executionStatus));
  }, [dispatch, executionStatus]);

  return null;
};

export default SignalRConnection;
