import Cookies from "js-cookie";
import { HttpClient } from "./http.services";
import { history } from "../history";
import { BASE_URL } from "../config";
import jwt_decode from "jwt-decode";

import { setUserData } from "../pages/signIn/signInSlice";
// import { setUser } from "../store/appReducer/appReducer";
const ApiRequestTypes = {
  get: "get",
  post: "post",
  del: "del",
  put: "put",
  interceptors: "interceptors",
  clearInterceptor: "clearInterceptor",
};

// Set Login Headers
const headersConfigFormData = (data) => ({
  "Content-Type": "application/x-www-form-urlencoded",
  ...(data ? data : {}),
});

let tdmInterceptor;
// Set Interceptors
const setInterceptors = (data) => {
  tdmInterceptor = HttpClient[ApiRequestTypes.interceptors](data);
  return tdmInterceptor;
};

// Remove  Interceptors
const removeInterceptors = () => {
  Cookies.remove("tdmAccessToken");
  Cookies.remove("tdmRefreshToken");

  return HttpClient[ApiRequestTypes.clearInterceptor]();
};

// Get App Variables
const getAppVariables = (FacilityId) => {
  const url = `Account/GetStylesByFacility`;
  return HttpClient[ApiRequestTypes.get](url, { FacilityId });
};

//Create Refresh token Form Data
const createRefreshTokenFormData = () => {
  const formdata = new FormData();
  formdata.append("grant_type", "refresh_token");
  formdata.append("scope", "openid offline_access securityassessmentscope");
  formdata.append("client_id", "reactSPA");
  formdata.append("client_secret", "reactSPAsecret");
  formdata.append("refresh_token", Cookies.get("tdmRefreshToken"));
  return formdata;
};

// Auth Interface
const authInterface = (method, url, data) => {
  return HttpClient[method](url, data).catch((error) => {
    if (error?.response?.status === 401) {
      return callLogin(createRefreshTokenFormData())
        .then((resp) => {
          Cookies.set("tdmAccessToken", resp?.data?.data?.tdmAccessToken, {
            secure: true,
            sameSite: "Strict",
          });
          Cookies.set("tdmRefreshToken", resp?.data?.data?.tdmRefreshToken, {
            secure: true,
            sameSite: "Strict",
          });
          if (resp?.data?.status === 200)
            return HttpClient[method](url, data ? data : null);
        })
        .catch(() => {
          clearCacheAndRedirectToLogin();
        });
    }
    return error;
  });
};

export const decodeData = (data) => {
  return jwt_decode(data);
};

const getUserData = () => {
  return decodeData(Cookies.get("tdmAccessToken"));
};

export const authenticateApp = () => (dispatch) => {
  return new Promise((resolve) => {
    const userData = getUserData();
    dispatch(setUserData(userData));
    ApiService.setInterceptors(Cookies.get("tdmAccessToken"));
    return resolve();
  });
};

const clearCacheAndRedirectToLogin = () => {
  removeInterceptors();
  history.replace("/login");
  window.location.reload();
};

//Login
const callLogin = (data) => {
  const url = `${BASE_URL}authenticator/Auth`;
  return HttpClient[ApiRequestTypes.post](url, data, {
    headers: headersConfigFormData(),
  });
};

const CallGetDahsboardData = () => {
  const url = `${BASE_URL}tdm/api/Dashboard/GetDashboardData`;
  return authInterface(ApiRequestTypes.get, url);
};
const CallGetConfigurationsData = () => {
  const url = `${BASE_URL}tdm/api/Configuration/GetAllConfigurations`;
  return HttpClient[ApiRequestTypes.get](url);
};

const callAddOrEditMasterTable = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/AddOrEditMasterTables`;
  return HttpClient[ApiRequestTypes.post](url, data);
};
const TestSourceDatabaseConnection = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/TestSourceDatabaseConnection`;
  return HttpClient[ApiRequestTypes.post](url, data);
};

const TestTargetDatabaseConnection = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/TestTargetDatabaseConnection`;
  return HttpClient[ApiRequestTypes.post](url, data);
};
const AddConnectionConfiguration = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/AddConnectionConfiguration`;
  return HttpClient[ApiRequestTypes.post](url, data);
};
const GetDatabaseDetails = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/GetDatabaseDetails?configurationId=${data}`;
  return HttpClient[ApiRequestTypes.get](url);
};

const getTableRules = () => {
  const url = `${BASE_URL}tdm/api/Configuration/GetTableRules`;
  return HttpClient[ApiRequestTypes.get](url);
};

const getColumnRules = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/GetColumnRules?databaseColumnId=${data}`;
  return HttpClient[ApiRequestTypes.get](url);
};

const ApplyTableRule = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/ApplyTableRule`;
  return HttpClient[ApiRequestTypes.post](url, data);
};

const ApplyColumnRule = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/ApplyColumnRule`;
  return HttpClient[ApiRequestTypes.post](url, data);
};

const callSaveDraft = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/SaveDraftConnectionConfiguration`;
  return HttpClient[ApiRequestTypes.post](url, data);
};

const callEditConnectionConfiguration = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/EditConnectionConfiguration`;
  return HttpClient[ApiRequestTypes.post](url, data);
};

const callGetConfiguration = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/GetConfiguration?configurationId=${data}`;
  return HttpClient[ApiRequestTypes.get](url);
};

const callGetMasterTableData = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/GetDatabaseTables?configurationId=${data}`;
  return HttpClient[ApiRequestTypes.get](url);
};

const getConfigurationOverview = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/GetConfigurationOverview?configurationId=${data}`;
  return HttpClient[ApiRequestTypes.get](url);
};
const getAllRules = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/GetRules`;
  return HttpClient[ApiRequestTypes.get](url);
};
const callSaveConfiguration = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/SaveConfiguration?configurationId=${data}`;
  return HttpClient[ApiRequestTypes.post](url, data);
};

const callDeleteConfig = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/DeleteConfiguration?configurationId=${data}`;
  return HttpClient[ApiRequestTypes.post](url, {});
};

const callEnableDisableConfig = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/EnableDisableConfiguration?configurationId=${data}`;
  return HttpClient[ApiRequestTypes.post](url, {});
};

const callCheckUniqueConfigName = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/CheckUniqueConfigurationName?configurationName=${data}`;
  return HttpClient[ApiRequestTypes.post](url, {});
};

const callAddNewRelation = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/addRelation`;
  return HttpClient[ApiRequestTypes.post](url, data);
};

const callStartExecution = (data) => {
  const url = `${BASE_URL}/tdm/api/Execution/StartExecution?configurationId=${data}`;
  return HttpClient[ApiRequestTypes.post](url, {});
};

const getExecutionDetailsByExecutionId = (data) => {
  const url = `${BASE_URL}/tdm/api/Execution/GetExecutionDetailsByExecutionId?executionId=${data}`;
  return HttpClient[ApiRequestTypes.post](url, {});
};

const callDeleteRelation = (data) => {
  const url = `${BASE_URL}/tdm/api/Configuration/Deleterelation`;
  return HttpClient[ApiRequestTypes.post](url, data);
};

const getSubtituteCategories = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/GetSubstitutionCategories`;
  return HttpClient[ApiRequestTypes.get](url);
};

const getDatabaseTypes = (data) => {
  const url = `${BASE_URL}tdm/api/Configuration/GetDatabaseTypes`;
  return HttpClient[ApiRequestTypes.get](url);
};

export const ApiService = {
  removeInterceptors,
  getAppVariables,
  setInterceptors,
  callLogin,
  createRefreshTokenFormData,
  CallGetDahsboardData,
  CallGetConfigurationsData,
  TestSourceDatabaseConnection,
  TestTargetDatabaseConnection,
  AddConnectionConfiguration,
  GetDatabaseDetails,
  ApplyTableRule,
  ApplyColumnRule,
  getTableRules,
  getColumnRules,
  getConfigurationOverview,
  callSaveConfiguration,
  callGetConfiguration,
  callSaveDraft,
  callCheckUniqueConfigName,
  callDeleteConfig,
  callEditConnectionConfiguration,
  callGetMasterTableData,
  callAddOrEditMasterTable,
  callEnableDisableConfig,
  callAddNewRelation,
  callStartExecution,
  getExecutionDetailsByExecutionId,
  callDeleteRelation,
  getAllRules,
  getSubtituteCategories,
  getDatabaseTypes,
};
