import React from "react";

const TDMLogo = () => {
  return (
    <svg
      width="62"
      height="32"
      viewBox="0 0 62 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="32" fill="white" fillOpacity="0.01" />
      <g clipPath="url(#clip0_12484_4151)">
        <path
          d="M45.9995 16C45.9995 12.8174 44.7352 9.76516 42.4847 7.51472C40.2342 5.26429 37.1819 4 33.9993 4C30.8166 4 27.7643 5.26429 25.5138 7.51472C23.2633 9.76516 21.999 12.8174 21.999 16H25.7492C26.9918 16 27.961 14.9587 28.4161 13.8023C28.715 13.0432 29.1684 12.3455 29.7565 11.7574C30.8818 10.6321 32.4079 10 33.9993 10C35.5906 10 37.1168 10.6321 38.242 11.7574C39.3673 12.8826 39.9994 14.4087 39.9994 16H45.9995Z"
          fill="url(#paint0_linear_12484_4151)"
        />
        <path
          d="M39.087 20.5922C39.6901 19.1363 40.0005 17.5758 40.0005 16H46C46 22.6274 40.6273 28 33.9997 28C32.9618 28 31.9546 27.8682 30.9941 27.6205C31.5376 27.4805 32.0717 27.3022 32.5925 27.0865C34.0485 26.4835 35.3713 25.5996 36.4857 24.4853C37.6 23.371 38.484 22.0481 39.087 20.5922Z"
          fill="#FBCF8E"
        />
        <path
          d="M16 16C16 17.5759 16.3104 19.1363 16.9135 20.5923C17.5165 22.0482 18.4005 23.371 19.5148 24.4854C20.6291 25.5996 21.952 26.4835 23.4079 27.0866C24.8639 27.6897 26.4243 28 28.0003 28C29.5761 28 31.1366 27.6897 32.5925 27.0866C34.0485 26.4835 35.3714 25.5996 36.4857 24.4854C37.6 23.371 38.4839 22.0482 39.087 20.5923C39.6901 19.1363 40.0005 17.5759 40.0005 16H36.2504C35.0077 16 34.0386 17.0414 33.5834 18.1976C33.5705 18.2306 33.5572 18.2634 33.5436 18.2962C33.2421 19.0241 32.8001 19.6856 32.2429 20.2427C31.6858 20.7998 31.0243 21.2418 30.2964 21.5433C29.5685 21.8449 28.7882 22.0001 28.0003 22.0001C27.2123 22.0001 26.4321 21.8449 25.7041 21.5433C24.9761 21.2418 24.3147 20.7998 23.7575 20.2427C23.2003 19.6856 22.7584 19.0241 22.4569 18.2962C22.1553 17.5682 22.0001 16.788 22.0001 16H16Z"
          fill="url(#paint1_linear_12484_4151)"
        />
        <path
          d="M16.001 16C16.001 9.37257 21.3737 4 28.0012 4C29.0388 4 30.0457 4.1317 31.0061 4.3793C28.9454 4.91015 27.0444 5.98446 25.514 7.51473C23.2636 9.76515 21.9993 12.8174 21.9993 16H16.001Z"
          fill="#73E5E2"
        />
      </g>
      <rect
        width="16"
        height="32"
        transform="translate(46)"
        fill="white"
        fillOpacity="0.01"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12484_4151"
          x1="45.9995"
          y1="10"
          x2="21.999"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E5E2" />
          <stop offset="1" stopColor="#394F87" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12484_4151"
          x1="16"
          y1="22.0001"
          x2="40.0005"
          y2="22.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBDC8E" />
          <stop offset="1" stopColor="#FB958E" />
        </linearGradient>
        <clipPath id="clip0_12484_4151">
          <rect
            width="30"
            height="24"
            fill="white"
            transform="translate(16 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TDMLogo;
