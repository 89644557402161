import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  TableHead,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import SkeletonTable from "./SkeletonTable";

const EnhancedTableHead = ({
  headCells,
  order,
  orderBy,
  onRequestSort,
  headerColor,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        background: headerColor,
        position: "sticky",
        top: 0,
        zIndex: 1,
      }}
    >
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: "#150E44",
              fontSize: "14px",
              fontWeight: "600",
              fontFamily: "Noto Sans",
              padding:
                index === 0
                  ? "12px 12px 12px 25px"
                  : index === 4
                  ? "12px 12px 12px 17px"
                  : "12px 12px 12px 0",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  headerColor: PropTypes.string,
};

function descendingComparator(a, b, orderBy) {
  if (!a || !b || !a.hasOwnProperty(orderBy) || !b.hasOwnProperty(orderBy)) {
    return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export default function EnhancedTable({
  rows = [],
  headCells,
  headerColor,
  cellPadding,
  loading,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy))?.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [order, orderBy, page, rows, rowsPerPage]);

  const RenderTableRows = ({ row, rowId }) => {
    return headCells?.map((cell, index) => {
      if (cell?.hasFunction) {
        const args = cell?.paramsRequired?.map((param) =>
          param === "rowId" ? rowId : param === "row" ? row : null
        );
        const functionParams = cell?.functionParams
          ? [...args, ...cell?.functionParams]
          : args;
        return (
          <TableCell
            key={cell?.id}
            sx={{
              padding: cellPadding,
              paddingLeft:
                (index === 0 ? "20px" : "") ||
                (index === 4 && row?.isDraft ? "17px" : ""),

              width: cell?.rowWidth || null,
              opacity:
                row?.isEnabled === false && row?.isDraft === false ? 0.5 : 1,
            }}
          >
            {cell?.isHover ? (
              <Tooltip
                title={row?.description || "No description added!"}
                placement="top"
                arrow
              >
                {cell?.function(...functionParams)}
              </Tooltip>
            ) : (
              cell?.function(...functionParams)
            )}
          </TableCell>
        );
      } else {
        return (
          <TableCell
            key={cell.id}
            sx={{
              padding: cellPadding,
              paddingLeft: index === 0 && "30px",
              width: cell.rowWidth || null,
              opacity:
                row?.isEnabled === false && row?.isDraft === false ? 0.5 : 1,

              fontSize: "14px",
            }}
          >
            {cell?.isHover ? (
              <Tooltip
                title={row?.description || "No description added!"}
                placement="top"
                arrow
              >
                {row?.[cell?.id]}
              </Tooltip>
            ) : (
              row?.[cell?.id]
            )}
          </TableCell>
        );
      }
    });
  };

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        borderRadius: "10px",
        boxShadow: 0,
        overflow: "hidden",
      }}
    >
      <TableContainer
        sx={{
          height: "calc(100% - 56px)",
          borderRadius: "10px",
          overflowY: "auto",
        }}
      >
        {loading ? (
          <SkeletonTable />
        ) : rows && rows?.length > 0 ? (
          <Table aria-labelledby="tableTitle" size={"medium"}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              headerColor={headerColor}
              sx={{ position: "sticky" }}
            />
            <TableBody>
              {visibleRows?.map((row, index) => (
                <TableRow hover tabIndex={-1} key={index + 1}>
                  <RenderTableRows row={row} rowId={index + 1} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No Data Found!
          </Box>
        )}
      </TableContainer>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          background: "white",
          borderRadius: "5px",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-toolbar": {
              color: "#00000099",
              fontFamily: "Noto Sans",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "19.92px",
              letterSpacing: "0.4000000059604645px",
              textAlign: "left",
            },
          }}
        />
      </Box>
    </Paper>
  );
}
